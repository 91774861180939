import React from 'react';
import { Box, Typography } from '@mui/material';

const FeatureList = ({brand,model,part}) => {

   
const features = [
  { 
      title: 'Part Inspection', 
      description: `At Premium Autocartz, we prioritize quality by thoroughly inspecting every used ${brand} part in our inventory. We conduct mechanical and electrical testing on the corresponding ${model} model and visually check for leaks, cracks, or any defects before stocking, ensuring only reliable parts reach our customers.` 
  },
  { 
      title: 'Warranty You Can Trust', 
      description: `Our extensive network of salvage yards offers a vast selection of ${brand} used parts. We confidently stand behind our products with a 60-day warranty on every item and offer extended coverage of up to one year on select parts for added peace of mind.` 
  },
  { 
      title: 'Efficient Nationwide Shipping', 
      description: `Premium Autocartz provides free shipping within the continental US. We’ve partnered with top freight and shipping carriers to ensure that any ${brand} part, engine, or transmission is delivered swiftly and securely, no matter where you need it.` 
  },
];
  return (
    <Box>
      {features.map((feature, index) => (
        <Box key={index} sx={{ mb: 2 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold',textAlign:"justify",lineHeight:"20px"  }}>
            {index + 1}) {feature.title}
          </Typography>
          <Typography variant="body2" color="black" sx={{textAlign:"justify",lineHeight:"20px" }}>{feature.description}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default FeatureList;
