import { Directions, WhatsApp } from "@mui/icons-material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import SearchIcon from "@mui/icons-material/Search";
import ShieldIcon from "@mui/icons-material/Shield";
import TwitterIcon from "@mui/icons-material/Twitter";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import Footer from "../components/Footer";
import HomeForm from "../components/HomeForm";
import Header from "../components/Header";
import Whatsapp from "../components/Whatsapp";
import HomeFormNew from "../components/HomeFormNew";
import { Navigate, useNavigate } from "react-router-dom";
import BrandsList from "../components/brandslist";
import SinglePageForm from "../components/SingPageForm";

const Home = ({ title, description }) => {


  const navigate = useNavigate();
  return (
    <Box width="100%" height="100vh"
    >
      <Box
        sx={{
          backgroundImage: `url(${process.env.REACT_APP_ASSET_URL}/Hero.png)`,
          backgroundSize: "cover",
          // backgroundPosition: "center",
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header textColor="black" />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            justifyContent: "space-around",
            marginTop: { xs: "20%", md: "10%" },
            gap: { xs: 5, md: 0 },
            padding: { xs: 3, md: 0 },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "608px" },
              display: "flex",
              gap: "20px",
              flexDirection: "column",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Typography
              variant="h3"
              sx={{
                color: "white",
                fontWeight: "bold",
                fontSize: { xs: "32px", md: "48px" },
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: "white",
                fontWeight: "300",
                fontSize: { xs: "18px", md: "24px" },
              }}
            >
              {description}
            </Typography>
          </Box>

          {/* <Box sx={{ width: { xs: "100%", md: "auto" ,marginBottom:"40px"}, maxWidth: "500px" }}> */}
            <SinglePageForm />
          {/* </Box> */}
        </Box>
      </Box>


      {      //second page
      }
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2.5,
            padding: { xs: "10%", sm: "15%" },
            py: { xs: 3, sm: 5 },
            bgcolor: "white",
            borderRadius: "60px 60px 0 0",
            overflow: "hidden",
          }}
        >
          <Typography
            variant="h3"
            sx={{ color: "#050b20", fontWeight: "bold", textAlign: "center" }}
          >
            Why Choose Us
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid item xs={12} md={6}>
              <img
                src={`${process.env.REACT_APP_ASSET_URL}/Background.png`}
                alt="Background"
                style={{
                  width: "100%",
                  maxWidth: "647px",
                  height: "auto",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  bgcolor: "#f9fbfc",
                  borderRadius: "16px",
                  border: "1px solid #e9e9e9",
                  p: 3,
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    color: "#050b20",
                    fontWeight: "bold",
                    mb: 2,
                    textAlign: { xs: "center", md: "left" },
                  }}
                >
                  At Premium Autocartz!
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#050b20",
                    fontWeight: "light",
                    mb: 3,
                    textAlign: { xs: "center", md: "left" },
                  }}
                >
                  We are committed to delivering exceptional service with a focus on
                  quality, reliability, and customer satisfaction. Our comprehensive
                  range of products is backed by a solid guarantee, ensuring you
                  receive the best value for your purchase.
                </Typography>
                <List>
                  {[
                    "Guaranteed Warranty.",
                    "Timely Delivery.",
                    "Competitive Pricing.",
                    "Delivered to Your Doorstep.",
                    "Reliability For A Decade.",
                    "Genuine Original Parts.",
                    "Payment Made Simple.",
                    "Hassle-Free Returns.",
                    "High Customer Satisfaction.",
                    "Global Online Support at Your Fingertips.",
                  ].map((item, index) => (
                    <ListItem key={index} sx={{ padding: "0px", display: "flex" }}>
                      <img
                        src={`${process.env.REACT_APP_ASSET_URL}/tick.png`}
                        alt="Tick Icon"
                        style={{ width: "25px", height: "25px", marginRight: "8px" }}
                      />
                      <ListItemText
                        primary={item}
                        sx={{ color: "#050b20", fontWeight: "light" }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Grid>
          </Grid>
        </Box>



        {/* third page */}
        <Box
          sx={{
            width: "100%",
            minHeight: "100vh",
            bgcolor: "#050b20",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            justifyContent: "space-around",
            padding: { md: 0 },
            gap: { xs: 3, md: 0 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
              width: { xs: "100%", md: "40%" },
              height: { xs: "auto", md: "685px" },
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
              <Typography
                variant="h4"
                sx={{ color: "white", fontWeight: "bold", textAlign: { xs: "center", md: "left" } }}
              >
                Premium Benefits for Every Purchase
              </Typography>
              <Typography
                variant="h6"
                sx={{ color: "white", textAlign: { xs: "center", md: "left" } }}
              >
                Enjoy exclusive benefits designed to enhance your shopping experience and guarantee peace of mind.
              </Typography>
              <Box sx={{ display: "flex", justifyContent: { xs: "center", md: "flex-start" }, gap: 2 }}>
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#ffd401",
                    borderRadius: "60px",
                    px: 3,
                    py: 1,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <MailIcon sx={{ color: "#050b20" }} />
                  <Typography
                    variant="body1"
                    sx={{ color: "#050b20", fontWeight: "bold" }}
                  >
                    Email us
                  </Typography>
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: "white",
                    borderRadius: "60px",
                    px: 3,
                    py: 1,
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <PhoneIcon sx={{ color: "white" }} />
                  <Typography
                    variant="body1"
                    sx={{ color: "white", fontWeight: "bold" }}
                  >
                    Call us
                  </Typography>
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: { xs: "300px", md: "407px" },
                borderRadius: "8px",
                backgroundImage: `url(${process.env.REACT_APP_ASSET_URL}/red_car_backview.png)`,
                backgroundSize: "cover",
                backgroundPosition: "50% 50%",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: { xs: "100%", md: "40%" },
            }}
          >
            {[
              {
                icon: <ShieldIcon sx={{ color: "#ffd401", fontSize: "40px" }} />,
                title: "60-Day Warranty",
                description:
                  "Our 60-day warranty gives you peace of mind with every purchase. Shop confidently, knowing your parts are covered.",
              },
              {
                icon: <LocalShippingIcon sx={{ color: "#ffd401", fontSize: "40px" }} />,
                title: "Free Shipping",
                description:
                  "We offer free shipping on all orders for a smooth, convenient shopping experience. Get the parts you need.",
              },
              {
                icon: <SearchIcon sx={{ color: "#ffd401", fontSize: "40px" }} />,
                title: "Search All Brands",
                description:
                  "Easily explore our wide selection of trusted brands. Find the perfect part, whether for classic models or modern vehicles.",
              },
            ].map((item, index) => (
              <Paper
                key={index}
                sx={{
                  p: 2,
                  borderRadius: "16px",
                  border: "1px solid #e9e9e9",
                  backgroundColor: "transparent",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    alignItems: { xs: "center", md: "flex-start" },
                  }}
                >
                  {item.icon}
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                        textAlign: { xs: "center", md: "left" },
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#fafafacc",
                        textAlign: { xs: "center", md: "left" },
                      }}
                    >
                      {item.description}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            ))}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            px: 7,
            py: 5,
            bgcolor: "white",
          }}
        >
          <Typography
            variant="h3"
            sx={{ color: "#050b20", fontWeight: "bold" }}
          >
            Our Top Selling Products
          </Typography>
          <Grid container spacing={3}>
            {[
              {
                img: "engine.png",
                alt: "Engine",
                title: "Engine",
                description: "Quality Used Engine Parts for Optimal Performance and Savings!",
              },
              {
                img: "bumper.png",
                alt: "Bumpers",
                title: "Bumpers",
                description: "Front and rear bumpers are frequently replaced after accidents.",
              },
              {
                img: "transmission_parts.png",
                alt: "Transmission Parts",
                title: "Transmission Parts",
                description: "Get top-quality used transmission parts for reliable performance and savings!",
              },
              {
                img: "headlights.png",
                alt: "Headlights",
                title: "Headlights",
                description: "Premium used headlights for top visibility and durability at a great price!",
              },
              {
                img: "fender.png",
                alt: "Fenders",
                title: "Fenders",
                description: "Quality used fenders for reliable protection and savings!",
              },
              {
                img: "side_mirror.png",
                alt: "Side Mirrors",
                title: "Side Mirrors",
                description: "High-quality used side mirrors for clear vision and affordability!",
              },
            ].map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="250"
                      image={`${process.env.REACT_APP_ASSET_URL}/${item.img}`}
                      alt={item.alt}
                    />
                    <CardContent>
                      <Typography variant="h6" sx={{ color: "#050b20" }}>
                        {item.title}
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#050b20" }}>
                        {item.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>

        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            px: 7,
            py: 5,
            bgcolor: "white",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h3"
              sx={{ color: "#050b20", fontWeight: "bold" }}
            >
              Featured Categories
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
              <Typography variant="body1" sx={{ color: "#050b20" }}>
                View All
              </Typography>
              <Box
                sx={{
                  width: "14px",
                  height: "14px",
                  backgroundImage: `url(${process.env.REACT_APP_ASSET_URL}/vector.svg)`,
                  backgroundSize: "100% 100%",
                }}
              />
            </Box>
          </Box>
          <Grid container spacing={3}>
            {[
              {
                title: "Engine Accessories",
                items: ["Engine Block", "Engine Cover", "Engine Core", "View All"],
              },
              {
                title: "Wheel Accessories",
                items: ["Wheel Bearing", "Wheel Screw", "Seals & Hubs", "View All"],
              },
              {
                title: "Break Parts",
                items: ["Break Tools", "Break Fluid", "Emergency Break System", "View All"],
              },
              {
                title: "Suspension Parts",
                items: ["Rear Suspension", "Location Arm", "Trailing Arm", "View All"],
              },
            ].map((category, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Paper
                  sx={{
                    p: 2,
                    borderRadius: "16px",
                    border: "1px solid #e9e9e9",
                  }}
                >
                  <Box
                    sx={{
                      bgcolor: "#ffd401",
                      borderRadius: "8px",
                      p: 1,
                      mb: 2,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ color: "#050b20", fontWeight: "bold" }}
                    >
                      {category.title}
                    </Typography>
                  </Box>
                  <Typography variant="body2" sx={{ color: "black" }}>
                    <ul>
                      {category.items.map((item, idx) => (
                        <li key={idx}>
                          {item === "View All" ? (
                            <span style={{ textDecoration: "underline" }}>{item}</span>
                          ) : (
                            item
                          )}
                        </li>
                      ))}
                    </ul>
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>

        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2.5,
            px: 7,
            py: 5,
            bgcolor: "white",
          }}
        >
          <Typography
            variant="h3"
            sx={{ color: "#050b20", fontWeight: "bold" }}
          >
            Explore Our Premium Brands
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
            <BrandsList />
          </Box>
        </Box>
        <Footer />
      </Box>
      <Whatsapp />
      {/* <Box
          sx={{
            position: "fixed",
            width: "100px",
            height: "100px",
            top: "90%%",
            left: "90%",
            bgcolor: "#ffd4011a",
            borderRadius: "50px",
          }}
        /> */}
    </Box>
  );
};

export default Home;
