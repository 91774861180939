import PhoneIcon from "@mui/icons-material/Phone";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, Button, Drawer, IconButton, Typography, List, ListItem, ListItemText } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function Header({ textColor }) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const phoneNumber = process.env.REACT_APP_CONTACT_NUMBER;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleCall = () => {
    window.location.href = `tel:${phoneNumber}`;
  };
  function formatUSPhoneNumber(phoneNumber) {
    // Match and extract parts of the phone number
    const match = phoneNumber.match(/^\+1(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const [, areaCode, firstPart, secondPart] = match;
        return `+1 (${areaCode})-${firstPart}-${secondPart}`;
    }
    return phoneNumber; // Return the original number if it doesn't match
}

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const menuItems = [
    { label: "Home", path: "/" },
    { label: "About Us", path: "/aboutus" },
    // { label: "Search Parts", path: "/search" },
    { label: "Contact Us", path: "/contactus" },
  ];

  return (
    <Box sx={{ position:"fixed",top:0,
    zIndex:"1100",width: "100%", height: "71px" ,background:"white",
    borderBottom:"1px solid #c1c1c1"}}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "95%",
          position: "relative",
          top: "22px",
        }}
      >
        <Typography
          variant="h6"
          sx={{ color: textColor, cursor: "pointer",marginLeft:"58px" }}
          onClick={() => navigate("/")}
        >
          Premium Autocartz
        </Typography>
        {isSmallScreen ? (
          <>
            <IconButton onClick={toggleDrawer(true)} sx={{ color: textColor }}>
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              <Box
                sx={{
                  width: 250,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  p: 2,
                }}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              >
                <List>
                  {menuItems.map((item) => (
                    <ListItem
                      button
                      key={item.label}
                      onClick={() => navigate(item.path)}
                    >
                      <ListItemText primary={item.label} />
                    </ListItem>
                  ))}
                </List>
                <Button
                  onClick={handleCall}
                  variant="contained"
                  sx={{
                    bgcolor: "#ffd401",
                    borderRadius: "60px",
                    px: 3,
                    py: 1,
                  }}
                >
                  <PhoneIcon sx={{ color: "#050b20" }} />
                  <Typography
                    variant="body1"
                    sx={{ color: "#050b20", fontWeight: "bold" }}
                  >
                    {formatUSPhoneNumber(phoneNumber)}
                  </Typography>
                </Button>
              </Box>
            </Drawer>
          </>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center", gap: 10 }}>
            <Box sx={{ display: "flex", gap: 12 }}>
              {menuItems.map((item) => (
                <Typography
                  key={item.label}
                  variant="body1"
                  sx={{ color: textColor, cursor: "pointer" }}
                  onClick={() => navigate(item.path)}
                >
                  {item.label}
                </Typography>
              ))}
            </Box>
            <Button
              onClick={handleCall}
              variant="contained"
              sx={{
                bgcolor: "#ffd401",
                borderRadius: "60px",
                px: 3,
                py: 1,
              }}
            >
              <PhoneIcon sx={{ color: "#050b20" }} />
              <Typography
                variant="body1"
                sx={{ color: "#050b20", fontWeight: "bold" }}
              >
                {formatUSPhoneNumber(phoneNumber)}
              </Typography>
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Header;
