import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, Typography } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';

const OptionsList = ({ itemsList, brand, model, part,modalOpen}) => {

  const location = useLocation();
  const currentUrl = location.pathname;



  if (part || itemsList.length==0) {

    let value = `${model?`${model} ${part?part:''}`:brand}`
    return (<Box sx={{ padding: '56px',display:"flex",flexDirection:"column" ,gap:"20px"}}>
      <Typography variant='h3'>Need a Part?</Typography>
      <Typography variant='p'>{`Submit a request form or contact us directly, and we'll help you find the perfect part for your ${value}. Expect quick responses, competitive pricing, and guaranteed quality. From quotes to delivery, we handle everything for a seamless, hassle-free experience.`}
      </Typography>

      <Button
                      variant="contained"
                      // onClick={()=>handleOpen(row)}
                      color="secondary"
                      sx={{ borderRadius: "60px",width:"fit-content" }} onClick={()=>modalOpen()}
                    >
                      Send Request
                    </Button>    </Box>)
  }


  return (

    <Box sx={{ padding: '56px' }}>
      <Typography variant='h3'>{model ? `${model} parts` : `${brand} models`}</Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, paddingTop: "24px" }}>
        {
          itemsList.map((item, index) => (
            <Chip key={item.item_id} label={`${model?model:''} ${item.item_name}`} variant="outlined"
              sx={{ cursor: "pointer" }} onClick={() => window.open(`${currentUrl}/${item.item_name}`, "_self")} />
          ))}
      </Box>
    </Box>

  );
};

export default OptionsList;
