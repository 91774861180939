import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const CustomSnackbar = ({ title,message, open, onClose }) => {
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      sx={{ display: "flex" }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={onClose}
        icon={false}
        sx={{
          display: "flex",
          alignItems: "center",
          maxWidth: 450,
          borderRadius: 2,
          p: 0,
          bgcolor: "white",
          color: "black",
          "& .MuiAlert-message": {
            display:"flex",
            width: "100%", // Ensure it spans the full width
            padding: 0, // Remove unwanted padding
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            bgcolor: "#FFD700",
            px: 2,
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
          }}
        >
        
        </Box>
        <CheckCircleIcon style={{ color: "#FFD700", width:"24px", height:"24px" ,margin:"8px"}} fontSize="medium" />
        <Box sx={{ pl: 2, pr: 1, py: 1.5, flexGrow: 1 }}>
          <Typography
            variant="subtitle1"
            fontWeight="bold"
            sx={{ mb: 0.5, fontSize: "16px" }}
          >
            {title}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "14px", lineHeight: 1.6 ,color:"#474747CC"}}>
            {message}
          </Typography>
        </Box>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={onClose}
          sx={{ alignSelf: "start", mt: 1 }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </MuiAlert>
    </Snackbar>
  );
};

export default CustomSnackbar;
