import React, { useState } from "react";
import { Autocomplete, Box, Button, TextField, Typography } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomSnackbar from "./CustomSnackBar";
import { parts } from "../data/parts";
import { model_make } from "../data/model-make";

const formSchema = yup.object().shape({
    // year: yup.string().matches(/^\d{4}$/, "Year must be a valid 4-digit number").required("Year is required"),
    model: yup.string().required("Model is required"),
    // part: yup.string().required("Part is required"),
    // transmission: yup.string().required("Transmission Type is required"),
    // name: yup.string().required("Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    // phone: yup.string().matches(/^\d{10}$/, "Phone number must be exactly 10 digits").required("Phone is required"),
    // zipcode: yup.string().matches(/^\d{5}$/, "Zipcode must be a valid 5-digit number").required("Zipcode is required"),
});

const currentYear = new Date().getFullYear();
const years = Array.from(new Array(100), (val, index) => currentYear - index);

function SinglePageForm({ mode, close }) {
    const [snackBarOpen, setSnackBarOpen] = useState(false);

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: {
            year: "",
            model: "",
            part: "",
            transmission: "",
            name: "",
            email: "",
            phone: "",
            zipcode: "",
        },
    });

    const onSubmit = async (data) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/request/submitform`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ formValues: data }),
        });
        const result = await response.json();

        console.log("Form data submitted:", data);

        if (mode && mode === "dialog") {
            close();
        }

        setSnackBarOpen(true);
        setTimeout(() => {
            window.location.reload();
        }, 2000);
    };

    function onSnackBarClose() {
        setSnackBarOpen(false);
    }

    return (
        <Box
            sx={{
                backgroundColor: "white",
                padding: "50px",
                borderRadius: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                width: { sm: "400px", md: "500px" },
                height: "auto",
                margin:"24px"
            }}
        >
            <CustomSnackbar
                title="Request Part"
                message="Request received! Our team will contact you soon. We appreciate your interest in Premium Autocartz."
                open={snackBarOpen}
                onClose={onSnackBarClose}
            />
            <Typography variant="h4" sx={{ color: "#050b20", fontWeight: "bold" }}>
                Find the part
            </Typography>

            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    width: "100%",
                }}
            >
                <Controller 
                    name="year"
                    control={control}
                    render={({ field }) => (
                        <Autocomplete
                        size="small"
                            {...field}
                            options={years}
                            getOptionLabel={(option) => option.toString()}
                            renderInput={(params) => (
                                <TextField 
                                    {...params}
                                    label="Select Year"
                                    variant="outlined"
                                    error={!!errors.year}
                                    helperText={errors.year?.message}
                                />
                            )}
                            onChange={(_, value) => field.onChange(value)}
                        />
                    )}
                />

                <Controller
                    name="model"
                    control={control}
                    render={({ field }) => (
                        <Autocomplete
                        size="small"
                            {...field}
                            options={model_make}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Model/Make"
                                    variant="outlined"
                                    error={!!errors.model}
                                    helperText={errors.model?.message}
                                />
                            )}
                            onChange={(_, value) => field.onChange(value ? value.label : "")}
                        />
                    )}
                />

                <Controller
                
                    name="part"
                    control={control}
                    render={({ field }) => (
                        <Autocomplete

                        size="small"
                            freeSolo
                            {...field}
                            options={parts}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Part"
                                    variant="outlined"
                                    error={!!errors.part}
                                    helperText={errors.part?.message}
                                />
                            )}
                            onChange={(_, value) => field.onChange(value ? value.label : "")}
                        />
                    )}
                />

                {/* <Controller
                    name="transmission"
                    control={control}
                    render={({ field }) => (
                        <Autocomplete
                            {...field}
                            options={["Automatic", "Manual"]}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Transmission"
                                    variant="outlined"
                                    error={!!errors.transmission}
                                    helperText={errors.transmission?.message}
                                />
                            )}
                            onChange={(_, value) => field.onChange(value)}
                        />
                    )}
                /> */}

                <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                        <TextField

                        size="small"
                            {...field}
                            label="Enter Your Name"
                            variant="outlined"
                            error={!!errors.name}
                            helperText={errors.name?.message}
                        />
                    )}
                />

                <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                        <TextField
                        size="small"
                            {...field}
                            label="Enter Email ID"
                            variant="outlined"
                            error={!!errors.email}
                            helperText={errors.email?.message}
                        />
                    )}
                />

                <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                        <TextField
                        size="small"
                            {...field}
                            inputProps={{ maxLength: 10, inputMode: "numeric", pattern: "[0-9]*" }}
                            label="Enter Phone Number"
                            variant="outlined"
                            error={!!errors.phone}
                            helperText={errors.phone?.message}
                        />
                    )}
                />

                <Controller
                    name="zipcode"
                    
                    control={control}
                    render={({ field }) => (
                        <TextField
                        size="small"
                            {...field}
                            inputProps={{ maxLength: 5, inputMode: "numeric", pattern: "[0-9]*" }}
                            label="Enter Zipcode"
                            variant="outlined"
                            error={!!errors.zipcode}
                            helperText={errors.zipcode?.message}
                        />
                    )}
                />

                <Button
                    type="submit"
                    variant="contained"
                    sx={{
                        bgcolor: "#ffd401",
                        color: "black",
                        marginTop: "16px",
                        width: "100%",
                    }}
                >
                    Send Request
                </Button>
            </Box>
        </Box>
    );
}

export default SinglePageForm;
