import React from 'react';
import { TextField, Typography } from '@mui/material';

const FormField = ({ label, placeholder, type = 'text', multiline = false, rows = 1, value, onChange,name }) => {
  return (
    <>
      <Typography>{label}</Typography>
      <TextField
        fullWidth
        placeholder={placeholder}
        variant="outlined"
        type={type}
        multiline={multiline}
        rows={rows}
        value={value}
        onChange={onChange}
        name={name}
      />
    </>
  );
};

export default FormField;
