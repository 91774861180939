import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import FeatureList from './featurelist';

const OverviewSection = ({brand,model,part,image}) => {

  let text = `Premium Autocartz delivers high-quality, reliable used ${brand} parts for a wide range of models. We specialize in sourcing top-performing components, known for their engineering precision and durability. Whether you're looking for a turbocharged engine for enhanced power, a fuel-efficient transmission, or hard-to-find body parts, we ensure that each ${brand} part meets stringent industry standards for performance and longevity. With meticulous inspection and advanced technology, our parts are designed to deliver a smooth, efficient driving experience while extending the life of your ${brand} ${model}.`;


    const overview = {
        image: image,
        text: text,
      };
  return (
    <Box
    sx={{
      padding: { xs: "16px", md: "56px" }, // Smaller padding for smaller screens
      display: "flex",
      flexDirection: { xs: "column", md: "row" }, // Stack on smaller screens
      gap: { xs: "20px", md: "40px" }, // Smaller gap on smaller screens
    }}
  >
    {/* Image Section */}
    <Box
      sx={{
        backgroundImage: `url(${overview.image})`,
        borderRadius: 2,
        backgroundSize: "cover",
        width: { xs: "100%", md: "35%" }, // Full width for small screens
        height: { xs: "200px", md: "auto" }, // Fixed height for small screens
        backgroundPosition: "center",
      }}
    />
  
    {/* Text and Feature List Section */}
    <Box sx={{ width: { xs: "100%", md: "55%" } }}>
      <Typography
        variant="body1"
        sx={{
          fontWeight: "bold",
          mb: 2,
          textAlign: "justify",
          fontSize: { xs: "14px", md: "16px" }, // Adjust font size for readability
        }}
      >
        {overview.text}
      </Typography>
      <FeatureList  brand={brand} model={model} part={part}/>
    </Box>
  </Box>
  
    
  );
};

export default OverviewSection;
