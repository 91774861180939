import { Box, Container, Modal, Typography } from "@mui/material";
import Header from "../components/Header";
import HomeFormNew from "../components/HomeFormNew";
import Footer from "../components/Footer";
import Whatsapp from "../components/Whatsapp";
import { useEffect, useState } from "react";
import OptionsList from "../components/OptionsList";
import OverviewSection from "../components/overview";
import { useParams } from "react-router-dom";
import axios from "axios";
import SinglePageForm from "../components/SingPageForm";

function PartsDetail() {
  const [data, setData] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false); // State for 404 handling

  const [open, setOpen] = useState(false);
  // const [modalData,setModalData] = useState()

  const handleOpen = (row) => {
    // setModalData(row)
    setOpen(true)
  }
  const handleClose = () => setOpen(false);

  const { brand, model, part } = useParams();
  console.log(brand, model, part);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/oldparts/data`,
        {
          params: { brand, car: model, part: part },
        }
      );
      if (response.status === 200) {
        setData(response.data);
      } else {
        setNotFound(true); // Mark as not found if status is not 200
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setNotFound(true); // Set 404 state if the response status is 404
      } else {
        console.error("Error fetching data:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, [brand, model]);

  // Show loading state
  if (isLoading) {
    return (
      <Box
        width="100%"
        minHeight="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h4" color="white">
          Loading...
        </Typography>
      </Box>
    );
  }

  // Show 404 error message
  if (notFound) {
    return (
      <Box
        width="100%"
        minHeight="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h4" color="white">
          404 - Part Not Found
        </Typography>
      </Box>
    );
  }



  return (
    <Box width="100%" minHeight="100vh">
      <Container>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Container sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 0,
          }}>
            <HomeFormNew mode="dialog" close={() => handleClose()} />
          </Container>

        </Modal>

      </Container>
      <Box
        sx={{
          backgroundImage: `url(${process.env.REACT_APP_ASSET_URL}/Background_used.jpg)`,
          backgroundSize: "cover",
          width: "100%",
          minHeight: "100vh",
        }}
      >
        <Header textColor="black" />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" }, // Stack on smaller screens
            alignItems: { xs: "center", md: "flex-start" }, // Center on smaller screens
            justifyContent: { xs: "center", md: "space-around" }, // Adjust space distribution
            // marginTop: "5%",
            gap: { xs: 4, md: 0 }, // Add gap for stacked layout
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "608px" }, // Full width on small screens
              display: "flex",
              gap: "20px",
              flexDirection: "column",
              marginTop: { xs: "20%", md: "10%" }, // Adjust top margin for smaller screens
              textAlign: { xs: "center", md: "left" }, // Center text on smaller screens
            }}
          >
            <Typography
              variant="h2"
              sx={{
                color: "white",
                fontWeight: "bold",
                fontSize: { xs: "1.5rem", md: "2.5rem" }, // Adjust font size for screens
              }}
            >
              {`Used ${data.title}${!part ? " parts" : ""}`}
            </Typography>
            <Typography
              variant="h3"
              sx={{
                color: "white",
                fontWeight: "300",
                fontSize: { xs: "1rem", md: "24px" }, // Adjust font size for screens
              }}
            >
              {data.description}
            </Typography>
          </Box>

          <Box
            sx={{
              width: {  md: "auto" }, // Full width for small screens
              maxWidth: "608px", // Limit width for larger screens
              marginTop: { xs: "20px", md: "150px" }, // Add spacing for stacked layout
            }}
          >
            <SinglePageForm />
          </Box>
        </Box>

      </Box>
      <OptionsList itemsList={data.itemsList} brand={brand} model={model} part={part} modalOpen={() => handleOpen()} />
      <OverviewSection brand={brand} model={model} part={part} image={data.model_image}/>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Footer />
      </Box>
      <Whatsapp />
    </Box>
  );
}

export default PartsDetail;
