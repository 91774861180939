import React from "react";
import {
    Box,
    Typography,
    TextField,
    Button,
    Paper,
    Container,
} from "@mui/material";
import { Grid2 } from '@mui/material';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import url from '../assets/Contact.jpg';
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const theme = createTheme({
    palette: {
        primary: {
            main: "#050b20",
        },
        secondary: {
            main: "#ffd401",
        },
    },
    typography: {
        fontFamily: "Open Sans, Helvetica",
        h2: {
            fontSize: "40px",
            fontWeight: 700,
            color: "#050b20",
        },
    },
});

// Validation schema using Yup
const schema = yup.object({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup.string().email("Enter a valid email").required("Email is required"),
    phone: yup.string()
        .matches(/^\d{10}$/, "Phone number must be 10 digits")
        .required("Phone is required"),
    message: yup.string().required("Message is required"),
}).required();

function Contact() {
    const navigate = useNavigate();

    // React Hook Form setup
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    // Form submission handler
    const onSubmit = async (data) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/request/submitquery`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ formValues: data }), // Convert data to JSON string
            });

            // Check if the response status is 200 OK
            if (response.ok) {
                alert("Message sent successfully");
                navigate("/");
            } else {
                throw new Error("Failed to send message");
            }
        } catch (error) {
            console.error("Failed to send message:", error);
            alert("Failed to send message");
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ backgroundColor: "white", width: "100%" }}>
                <Header textColor="black" />

                <Container
  sx={{
    maxWidth: "100%", // Ensure it occupies full width
    px: { xs: 2, sm: 4, md: 6 }, // Add responsive padding
    py: { xs: 2, md: 4 },
    marginTop: { xs: "20%", md: "5%" },
  }}
>
  <Box sx={{ display: 'flex', justifyContent: 'center', margin: '10px' }}>
    <Typography variant="h2" sx={{ fontSize: { xs: '1.5rem', md: '2.5rem' }, textAlign: 'center' }}>
      Any Queries?
    </Typography>
  </Box>

  <Box
    sx={{
      bgcolor: "background.paper",
      border: "1px solid",
      borderColor: "divider",
      borderRadius: 2,
      width: "100%", // Ensure full width responsiveness
      maxWidth: "1360px", // Limit maximum width
      margin: "0 auto", // Center horizontally
    //   p: { xs: 2, md: 4 }, // Add responsive padding
      display: 'flex',
      flexDirection: { xs: 'column', lg: 'row' }, // Stack on smaller screens
      alignItems: 'center',
      gap: { xs: 2, lg: 4 }, // Add spacing between form and image
    }}
  >
    {/* Form Section */}
    <Box sx={{ width: { xs: '100%', lg: '640px' } }}>
      <Grid2 container spacing={2} mt={3} sx={{padding:{xs:2}}}>
        <Grid2 item xs={12} md={6}>
          <Typography>First Name*</Typography>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                placeholder="Enter First Name"
                variant="outlined"
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
              />
            )}
          />
        </Grid2>
        <Grid2 item xs={12} md={6}>
          <Typography>Last Name*</Typography>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                placeholder="Enter Last Name"
                variant="outlined"
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
              />
            )}
          />
        </Grid2>
        <Grid2 item xs={12} md={6}>
          <Typography>Email*</Typography>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                placeholder="Enter Email"
                variant="outlined"
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            )}
          />
        </Grid2>
        <Grid2 item xs={12} md={6}>
          <Typography>Phone Number*</Typography>
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                placeholder="Enter Phone Number"
                variant="outlined"
                error={!!errors.phone}
                helperText={errors.phone?.message}
                inputProps={{ maxLength: 10 }}
              />
            )}
          />
        </Grid2>
        <Grid2 item xs={12}>
          <Typography>Message*</Typography>
          <Controller
            name="message"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                placeholder="Describe your message here.."
                variant="outlined"
                multiline
                rows={7}
                error={!!errors.message}
                helperText={errors.message?.message}
              />
            )}
          />
        </Grid2>
        <Grid2 item xs={12} textAlign="center">
          <Button
            variant="contained"
            sx={{
              bgcolor: "#ffd401",
              borderRadius: "50px",
              px: 8,
              py: 1,
              color: "black",
              fontSize: { xs: '0.875rem', md: '1rem' },
            }}
            onClick={handleSubmit(onSubmit)}
          >
            Send Message
          </Button>
        </Grid2>
      </Grid2>
    </Box>

    {/* Image Section */}
    <Paper
      variant="outlined"
      sx={{
        width: { xs: '100%', lg: '558px' },
        height: 'auto',
        borderRadius: 2,
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        src={url}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          borderRadius: '16px',
        }}
        alt="Description"
      />
    </Paper>
  </Box>
</Container>


                <Footer />
            </Box>
        </ThemeProvider>
    );
}

export default Contact;
