import { WhatsApp } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function Whatsapp() {
  const message = "Hi, please provide below part details";
  const phoneNumber = process.env.REACT_APP_CONTACT_NUMBER;

  const handleWhatsAppClick = () => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank"); // Opens in a new tab
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        position: "fixed",
        width: isSmallScreen ? "50px" : "60px",
        height: isSmallScreen ? "50px" : "60px",
        bottom: isSmallScreen ? "10%" : "5%",
        right: isSmallScreen ? "5%" : "3%",
        bgcolor: "#ffd401",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
      }}
      onClick={handleWhatsAppClick}
    >
      <WhatsApp
        sx={{
          fontSize: isSmallScreen ? "20px" : "30px",
          color: "#050b20",
        }}
      />
    </Box>
  );
}

export default Whatsapp;
