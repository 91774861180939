import logo from './logo.svg';
import './App.css';
import PremiumAutoCartz from './pages/PremiumCartz'
import Contact from './pages/contact';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import { createTheme, ThemeProvider } from '@mui/material';
import Aboutus from './pages/AboutUs';
import PartsDetail from './pages/PartsDetail';
import CustomSnackbar from './components/CustomSnackBar';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#050b20",
      },
      secondary: {
        main: "#ffd401",
      },
      success: {
        main: "#008000cc",
      },
      text: {
        primary: "#050b20",
        secondary: "#ffffff",
      },
    },
    typography: {
      fontFamily: "Open Sans, Helvetica",
      h1: {
        fontSize: "52px",
        fontWeight: 600,
        color: "#ffffff",
      },
      h2: {
        fontSize: "40px",
        fontWeight: 700,
        color: "#050b20",
      },
      h3: {
        fontSize: "28px",
        fontWeight: 700,
        color: "#050b20",
      },
      body1: {
        fontSize: "17px",
        fontWeight: 400,
        color: "#050b20",
      },
      body2: {
        fontSize: "14px",
        fontWeight: 400,
        color: "white",
      },
      button: {
        textTransform: "none",
      },
    },
  });
  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home title={"Trusted Parts for Every Journey"}
            description={`Delivering quality used parts to bring new life to your vehicle.
              Dedicated to providing authentic, dependable parts for every ride.`}/>}></Route>
            <Route path="/contactus" element={<Contact />}></Route>
            <Route path="/search" element={<PremiumAutoCartz />}></Route>
            <Route path="/aboutus" element={<Aboutus title={"About us"}
            description={`Discover our commitment to quality, affordability, and sustainability—driving value in every part we offer.`}/>}/>

            <Route path="/used/:brand/:model?/:part?" element={<PartsDetail/>}/>
            <Route path="snack" element={<CustomSnackbar message="Request received! Our team will contact you soon. 
We appreciate your interest in Premium Autocartz." open={true}/>}/>
          </Routes>

        </BrowserRouter>
      </ThemeProvider>



    </>

  );
}

export default App;
