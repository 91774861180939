import { Directions, WhatsApp } from "@mui/icons-material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import SearchIcon from "@mui/icons-material/Search";
import ShieldIcon from "@mui/icons-material/Shield";
import TwitterIcon from "@mui/icons-material/Twitter";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import Footer from "../components/Footer";
import HomeForm from "../components/HomeForm";
import ValuesGrid from "../components/valuesgrid";
import Header from "../components/Header";
import Mission from "../components/Mission";
import Whatsapp from "../components/Whatsapp";
import HomeFormNew from "../components/HomeFormNew";
import SinglePageForm from "../components/SingPageForm";

const Aboutus = ({title,description}) => {
  return (
    <Box width="100%" height="100vh"
    >
      <Box
        sx={{
          backgroundImage: `url(${process.env.REACT_APP_ASSET_URL}/Hero.png)`,
          backgroundSize: "cover",
          width: "100%",
          minHeight: "100vh",
        }}
      >

        <Header textColor="black"/>
        <Box
      sx={{
        
        marginTop: { xs: "20%", md: "5%" },
        paddingX: { xs: "16px", sm: "40px", md: "100px" }, // Adjust padding based on screen size
      }}
    >
      <Grid
        container
        spacing={4}
        alignItems="center"
        sx={{
          flexDirection: { xs: "column", sm: "row" }, 
          justifyContent:"space-around"// Stack vertically on small screens
        }}
      >
        {/* Left Section */}
        <Grid item xs={12} sm={6}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Typography
              variant="h2"
              sx={{ color: "white", fontWeight: "bold" }}
            >
              {title}
            </Typography>
            <Typography
              variant="h3"
              sx={{
                color: "white",
                fontWeight: "300",
                fontSize: { xs: "20px", sm: "24px" }, // Adjust font size for small screens
              }}
            >
              {description}
            </Typography>
          </Box>
        </Grid>

        {/* Right Section - HomeFormNew component */}
        <Grid item xs={12} sm={4} sx={{marginTop:"5%"}}>
          <SinglePageForm />
        </Grid>
      </Grid>
    </Box>
      </Box>

      <Mission/>

      {      //second page
      }
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      > */}
        <ValuesGrid/>
        
      {/* </Box> */}
      <Footer/>
      <Whatsapp/>
      {/* <Box
          sx={{
            position: "fixed",
            width: "100px",
            height: "100px",
            top: "90%%",
            left: "90%",
            bgcolor: "#ffd4011a",
            borderRadius: "50px",
          }}
        /> */}
    </Box>
  );
};

export default Aboutus;
