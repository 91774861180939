import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  PaginationItem,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Autocomplete,
  TextField,
  Modal,
  Stack,
  Grid2,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ChatIcon from "@mui/icons-material/Chat";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SendRequest } from "../components/sendrequest";
import MainSearch from "../components/MainSearch";
import { useDispatch, useSelector } from "react-redux";
import PartsTable from "../components/PartsTable";
import { LiaFacebookF } from "react-icons/lia";
import { TiSocialLinkedin, TiSocialTwitter } from "react-icons/ti";
import { IoLogoInstagram } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { fetchCarParts } from "../redux/slice/carpartsSlice";
import Footer from "../components/Footer";
import Header from "../components/Header";

const theme = createTheme({
  palette: {
    primary: {
      main: "#050b20",
    },
    secondary: {
      main: "#ffd401",
    },
    success: {
      main: "#008000cc",
    },
    text: {
      primary: "#050b20",
      secondary: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Open Sans, Helvetica",
    h1: {
      fontSize: "52px",
      fontWeight: 600,
      color: "#ffffff",
    },
    h2: {
      fontSize: "40px",
      fontWeight: 700,
      color: "#050b20",
    },
    h3: {
      fontSize: "28px",
      fontWeight: 700,
      color: "#050b20",
    },
    body1: {
      fontSize: "17px",
      fontWeight: 400,
      color: "#050b20",
    },
    body2: {
      fontSize: "14px",
      fontWeight: 400,
      color: "white",
    },
    button: {
      textTransform: "none",
    },
  },
});

const partsData = [
  {
    year: "2016",
    part: "Engine",
    model: "Audi A6",
    image: "./Table.jpg",
    description: "Lorem ipsum dolor sit amet consectetur.",
    miles: 10,
    grade: "AFT",
    price: "$6300",
  },
  // Repeat the same data for other rows
];

const PremiumAutoCartz = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchResultRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [modalData,setModalData] = useState()

  const handleOpen = (row) => {
    setModalData(row)
    setOpen(true)
  }
  const handleClose = () => setOpen(false);

  const currentYear = new Date().getFullYear();

  useEffect(()=>{
    dispatch(fetchCarParts());
  },[])

  

  const years = Array.from(new Array(100), (val, index) => currentYear - index);
  
  const { parts, loading, error } = useSelector((state) => state.carParts);


  const handleSearchClick = () => {
    if (searchResultRef.current) {
      searchResultRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (

      <Box   sx={{backgroundColor: "white", width: "100%" }}>

        <Box sx={{
          backgroundImage: `url(${process.env.REACT_APP_ASSET_URL}/Hero.png)`,
          backgroundSize: "cover",
          height: "100vh",
          width: '100%',
        }}>
          {/* header box */}
          
          <Header textColor="black"/>

          <Container>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
            >
              <Container sx={{
            width: '692px',
            height: '669px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 0,
          }}>
                <SendRequest close={handleClose} modalData={modalData}/>

              </Container>

            </Modal>

          </Container>


          <Container maxWidth="xl" style={{ marginTop: '300px' }}>
            <Typography variant="h1" align="center">
              Find Your Perfect Automobile part
            </Typography>
            <Box
               sx={{
                display: "flex",
                justifyContent: "center",
                mt: 4,
              }}
            >
              <Box
                sx={{
                  backgroundColor: "white",
                  borderRadius: "80px",
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 20px",
                  justifyContent: "center"
                }}
              >
                <MainSearch scroll={handleSearchClick}/>
              </Box>
            </Box>
          </Container>
        </Box>


        {/* table search */}
        <Box
          sx={{

            alignItems: "center",
            width: '100%'
          }}>
          <Container maxWidth="xl" sx={{ mt: 8 }} ref={searchResultRef}>
            <Typography variant="h2" align="center">
              Search Result
            </Typography>
            <Typography variant="h3" sx={{ mt: 4 }}>
              All Parts
            </Typography>

            <TableContainer component={Paper} sx={{ mt: 4 }}>
              <PartsTable parts={parts} loading={loading} handleOpen={handleOpen}/>
            </TableContainer>
          
            {/* Pagination */}
          </Container>
        </Box>


        {/* footer */}
       <Footer/>
      </Box>
  );
};

export default PremiumAutoCartz;