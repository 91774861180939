import React from 'react';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SchoolIcon from '@mui/icons-material/School';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
// import EcoIcon from '@mui/icons-material/Eco';
import LoopSharpIcon from '@mui/icons-material/LoopSharp';


const iconMapping = {
  "Quality & Reliability": <img style={{width:"60px"}} src={`${process.env.REACT_APP_ASSET_URL}/Vector.png`}></img>,
  "Sustainability": <img style={{width:"60px"}} src={`${process.env.REACT_APP_ASSET_URL}/sustain.png`}></img>,
  "Affordability": <img style={{width:"60px"}} src={`${process.env.REACT_APP_ASSET_URL}/afford.png`}></img>,
  "Expertise & Knowledge":<img style={{width:"60px"}} src={`${process.env.REACT_APP_ASSET_URL}/knowledge.png`}></img>,
  "Trust & Transparency": <img style={{width:"60px"}} src={`${process.env.REACT_APP_ASSET_URL}/trust.png`}></img>,
  "Commitment to Service": <img style={{width:"60px"}} src={`${process.env.REACT_APP_ASSET_URL}/headphone.png`}></img>
};

const valuesData = [
    { title: 'Quality & Reliability', description: 'Each part undergoes a thorough inspection to ensure safety, durability, and dependable performance.' },
    { title: 'Sustainability', description: 'We embrace sustainability by refurbishing car parts, reducing waste, and minimizing environmental impact.' },
    { title: 'Affordability', description: 'We make vehicle repairs affordable by providing cost-effective alternatives without compromising on quality.' },
    { title: 'Expertise & Knowledge', description: 'With industry experience, we help customers find the best parts for their specific needs.' },
    { title: 'Trust & Transparency', description: 'Honesty and integrity are key; we build trust through transparent pricing and product information.' },
    { title: 'Commitment to Service', description: 'Our team is dedicated to exceptional service, offering expert advice and responsive support at every step.' }
  ];

const ValuesGrid = ({ values }) => {
  return (
    <Box sx={{ textAlign: 'center', mt: 4 }}>
      <Typography variant="h2" gutterBottom>
        Our Values
      </Typography>
      <Grid
        container
        spacing={4}
        sx={{
          marginTop: "40px",
          paddingLeft: { xs: "0", sm: "0", md: "100px" },
          justifyContent: "center", // Center items horizontally
        }}
      >
        {valuesData.map((value, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              variant="outlined"
              sx={{
                height: '320px',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: 'center',
                p: 2,
                maxWidth: "450px",
                backgroundColor: "#F9FBFC",
                borderRadius: "10px",
                margin: "0 auto", // Center card on screen
              }}
            >
              <Box>{iconMapping[value.title]}</Box>
              <CardContent>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontWeight: "600",
                    fontSize: "24px",
                    lineHeight: "27.24px",
                  }}
                >
                  {value.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="black"
                  sx={{
                    fontSize: "20px",
                    lineHeight: "27.24px",
                  }}
                >
                  {value.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ValuesGrid
