// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import carPartsReducer from './slice/carpartsSlice';

const store = configureStore({
  reducer: {
    carParts: carPartsReducer, // Add your slice reducer here
  },
});

export default store;
