import React, { useState } from 'react';
import {
  Autocomplete,
  TextField,
  Button,
  Divider,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { model_make } from '../data/model-make';
import { parts } from '../data/parts';
import { fetchCarParts } from '../redux/slice/carpartsSlice';
import { useDispatch } from 'react-redux';

const MainSearch = ({ scroll }) => {
  const [modelMake, setModelMake] = useState('');
  const [year, setYear] = useState(0);
  const [part, setPart] = useState('');
  const [pincode, setPincode] = useState('');

  const dispatch = useDispatch();


  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(100), (val, index) => currentYear - index);


  const handleSearchClick = async () => {
    console.log(modelMake, year, part, pincode);

    const params = {
      model_make: modelMake,
      year: year,
      part: part,
      pincode: pincode,
    }
    try {
      //   // Make GET request to your backend with the selected values
      dispatch(fetchCarParts(params));
      scroll()
      // Handle the response data as needed
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Autocomplete
      freeSolo
        options={model_make}
        onChange={(event, value) => setModelMake(value?value.label:'')}
        renderInput={(params) => (
          <TextField {...params} label="Model/Make"  sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none', // Remove border for outlined variant
              },
            },
            '& .MuiInputBase-root': {
              border: 'none', // Remove any base border
              boxShadow: 'none', // Remove any shadow if it exists
            },
          }}/>
        )}
        sx={{ width: '250px', mr: 2 }}
      />
      <Divider orientation="vertical" variant="middle" />

      <Autocomplete
        options={years}
        getOptionLabel={(option) => option.toString()} // Convert number to string
        onChange={(event, value) => setYear(value)}
        renderInput={(params) => (
          <TextField {...params} label="Year"    sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none', // Remove border for outlined variant
              },
            },
            '& .MuiInputBase-root': {
              border: 'none', // Remove any base border
              boxShadow: 'none', // Remove any shadow if it exists
            },
          }}/>
        )}
        sx={{ width: '150px', mr: 2 }}
      />
      <Divider orientation="vertical" variant="middle" />
      <Autocomplete 
      freesolo
        options={parts}
        onChange={(event, value) => setPart(value?value.label:'')}
        renderInput={(params) => (
          <TextField {...params} label="Part"    sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none', // Remove border for outlined variant
              },
            },
            '& .MuiInputBase-root': {
              border: 'none', // Remove any base border
              boxShadow: 'none', // Remove any shadow if it exists
            },
          }}/>
        )}
        sx={{ width: '250px', mr: 2 }}
      />
      <Divider orientation="vertical" variant="middle" />
      <TextField onChange={(event) => setPincode(event.target.value)} 
      label="zipcode"   sx={{
        width: '150px', mr: 2 ,
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            border: 'none', // Remove border for outlined variant
          },
        },
        '& .MuiInputBase-root': {
          border: 'none', // Remove any base border
          boxShadow: 'none', // Remove any shadow if it exists
        },
      }}/>

      <Button
        onClick={handleSearchClick}
        variant="contained"
        color="secondary"
        startIcon={<SearchIcon />}
        sx={{ borderRadius: "60px", padding: "10px 20px" }}
      >
        Search Part
      </Button>
    </div>
  );
};

export default MainSearch;
