import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { IoLogoInstagram } from "react-icons/io";
import { LiaFacebookF } from "react-icons/lia";
import { TiSocialLinkedin, TiSocialTwitter } from "react-icons/ti";

function Footer() {
  return (
    <Box sx={{ backgroundColor: "#050b20", color: "white", mt: 8, py: 6 }}>
      <Box
        sx={{
          borderBottom: "1px solid white",
          paddingBottom: "20px",
        }}
      >
        <Container>
          <Grid container spacing={4} justifyContent="center">
            {/* Brand Section */}
            <Grid item xs={12} sm={6} md={3}>
              <Stack spacing={2}>
                <Typography variant="h6">Premium Autocartz</Typography>
                <Typography variant="body1">Connect With Us</Typography>
                <Stack direction="row" spacing={2}>
                  <LiaFacebookF
                    style={{
                      fontSize: "25px",
                      border: "1px solid white",
                      padding: "5px",
                      borderRadius: "50%",
                    }}
                  />
                  <TiSocialTwitter
                    style={{
                      fontSize: "25px",
                      border: "1px solid white",
                      padding: "7px",
                      borderRadius: "50%",
                    }}
                  />
                  <IoLogoInstagram
                    style={{
                      fontSize: "25px",
                      border: "1px solid white",
                      padding: "7px",
                      borderRadius: "50%",
                    }}
                  />
                  <TiSocialLinkedin
                    style={{
                      fontSize: "25px",
                      border: "1px solid white",
                      padding: "7px",
                      borderRadius: "50%",
                    }}
                  />
                </Stack>
              </Stack>
            </Grid>

            {/* Sale Hours Section */}
            <Grid item xs={12} sm={6} md={3}>
              <Stack spacing={2}>
                <Typography variant="h6">Sale Hours</Typography>
                <Typography variant="body2">Monday-Friday: 09.00AM-09.00PM</Typography>
                <Typography variant="body2">Saturday: 09.00AM-07.00PM</Typography>
                <Typography variant="body2">Sunday: Closed</Typography>
              </Stack>
            </Grid>

            {/* Company Section */}
            <Grid item xs={12} sm={6} md={3}>
              <Stack spacing={2}>
                <Typography variant="h6">Company</Typography>
                <Typography variant="body2">Why Choose Us?</Typography>
                <Typography variant="body2">Testimonials</Typography>
                <Typography variant="body2">Contact Us</Typography>
              </Stack>
            </Grid>

            {/* Useful Links Section */}
            <Grid item xs={12} sm={6} md={3}>
              <Stack spacing={2}>
                <Typography variant="h6">Useful Links</Typography>
                <Typography variant="body2">Terms & Conditions</Typography>
                <Typography variant="body2">Privacy & Policy</Typography>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Footer Bottom Section */}
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Typography variant="body2">
          © 2024 Premium Autocartz. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
