// src/slices/carPartsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Async thunk for fetching car parts from the backend
export const fetchCarParts = createAsyncThunk('carParts/fetchCarParts', async (params) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/search`,{
        method:"POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      });
      const parts = await response.json();

  return {parts,searchDetails:params};
});

// Create a slice
const carPartsSlice = createSlice({
  name: 'carParts',
  initialState: {
    parts: [],
    searchDetails:{},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCarParts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCarParts.fulfilled, (state, action) => {
        console.log("add completed",action)
        state.loading = false;
        state.parts = action.payload.parts;
        state.searchDetails = action.payload.searchDetails // Update the state with fetched car parts
      })
      .addCase(fetchCarParts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; // Capture any error messages
      });
  },
});

// Export the reducer to be used in the store
export default carPartsSlice.reducer;
