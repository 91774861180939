import { Box, Grid, Typography } from "@mui/material";

function Mission() {
    return (
        <Box
        sx={{
          paddingLeft: { xs: "16px", sm: "100px" }, // Adjust padding for smaller screens
          paddingRight: { xs: "16px", sm: "100px" }, // Adjust padding for smaller screens
          paddingTop: "80px",
          paddingBottom: "80px",
        }}
      >
        <Typography variant="h2" sx={{ mb: 4 }}>
          Our Mission & Vision
        </Typography>
  
        <Grid container spacing={4} alignItems="center">
          {/* Left Section */}
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                fontFamily: "Open Sans",
                fontSize: "20px",
                fontWeight: 300,
                lineHeight: "27.24px",
                textAlign: { xs: "center", sm: "left" }, // Center text on smaller screens
              }}
            >
              <Typography fontSize="inherit" sx={{ mb: 2 }}>
                At Premium Autocartz, our mission is to empower drivers, mechanics, and car enthusiasts alike by providing a trusted source for high-quality used and refurbished car parts. We’re passionate about making vehicle maintenance more accessible and affordable for everyone, ensuring that every part you receive meets our high standards for performance and reliability.
              </Typography>
  
              <Typography fontSize="inherit">
                We have built a reputation for sourcing rare and hard-to-find components that help our customers keep their vehicles running smoothly for years to come. Whether you’re a seasoned mechanic, a DIY car enthusiast, or someone seeking a practical solution to keep your car in top condition, Premium Autocartz is here to support you.
              </Typography>
            </Box>
          </Grid>
  
          {/* Right Section - Image */}
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center", // Center image on smaller screens
                alignItems: "center",
              }}
            >
              <img
                src={`${process.env.REACT_APP_ASSET_URL}/contactus.png`}
                alt="Contact Us"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
}

export default Mission;