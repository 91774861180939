import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  InputAdornment,
  Grid,
} from "@mui/material";
import { Grid2 } from '@mui/material';
import { useSelector } from "react-redux";
import FormField from "./FormField";

export const SendRequest = ({close,modalData}) => {


  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phone: '',
    vin: '',
    additionalDetails: '',
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };




  const {searchDetails} = useSelector((state) => state.carParts)


  const handleSubmit = async () => {
    const requestData = {
      formValues,
      searchDetails,
      modalData,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/request/partrequest`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Success:', data);
      // Handle success (e.g., show a notification)
    } catch (error) {
      console.error('Error:', error);
      // Handle error (e.g., show an error notification)
    }
    close()
  };


  return (
    <Box
      sx={{
        p: 4,
        bgcolor: "background.paper",
        border: "1px solid",
        borderColor: "divider",
        borderRadius: 2,
        minWidth: 692,
        minHeight: 669,
        width: "100%",
        maxWidth: "100%",
      }}
    >
      {/* Header */}
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography variant="h5" fontWeight="bold">
          Send Request
        </Typography>
        <CloseIcon sx={{ cursor: "pointer" }} onClick={close} />
      </Grid>

      {/* Search Summary */}
      {searchDetails && searchDetails.model_make != '' ? (
        <Paper
          elevation={0}
          sx={{
            p: 2,
            mt: 3,
            bgcolor: "rgba(244, 164, 3, 0.1)",
            borderRadius: 2,
          }}
        >
          <Typography variant="subtitle1" fontWeight="bold">
            Search Summary
          </Typography>
          <Grid container spacing={5} mt={1}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="black">
                <strong>Model/Make:</strong> {searchDetails.model_make}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="black">
                <strong>Year:</strong> {searchDetails.year}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="black">
                <strong>Part:</strong> {searchDetails.part}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="black">
                <strong>Zipcode:</strong> {searchDetails.pincode}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      ) : null}

      {/* Selected Component */}
      <Paper
        elevation={0}
        sx={{
          p: 2,
          mt: 3,
          bgcolor: "rgba(244, 164, 3, 0.1)",
          borderRadius: 2,
        }}
      >
        <Typography variant="subtitle1" fontWeight="bold">
          Selected Component
        </Typography>
        <Grid container spacing={5} mt={1}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="black">
              <strong>Model/Make:</strong> {modalData.model_make}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="black">
              <strong>Year:</strong> {modalData.year}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="black">
              <strong>Part:</strong> {modalData.part}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="black">
              <strong>Zipcode:</strong> {modalData.pincode}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      {/* Form Fields */}
      <Grid
        container
        spacing={3}
        mt={3}
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
          gap: "20px",
        }}
      >
        <Grid item xs={12} sm={6}>
          <FormField
            label="Name"
            placeholder="Enter Name"
            value={formValues.name}
            onChange={handleChange}
            name="name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            label="Email ID*"
            placeholder="Enter Email ID"
            type="email"
            value={formValues.email}
            onChange={handleChange}
            name="email"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            label="Phone Number*"
            placeholder="Enter Phone Number"
            type="tel"
            value={formValues.phone}
            onChange={handleChange}
            name="phone"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormField
            label="VIN Number"
            placeholder="Enter VIN Number"
            value={formValues.vin}
            onChange={handleChange}
            name="vin"
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            label="Additional Details"
            placeholder="Enter Additional Details of the parts"
            multiline
            rows={6}
            value={formValues.additionalDetails}
            onChange={handleChange}
            name="additionalDetails"
          />
        </Grid>
      </Grid>

      {/* Buttons */}
      <Grid container spacing={2} mt={3} justifyContent="flex-end">
        <Grid item>
          <Button
            onClick={close}
            variant="outlined"
            sx={{
              borderRadius: "50px",
              px: 4,
              py: 1,
            }}
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="secondary"
            variant="contained"
            sx={{
              borderRadius: "50px",
              px: 4,
              py: 1,
            }}
            onClick={handleSubmit} // Call handleSubmit on click
          >
            Send
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};