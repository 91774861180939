import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Box,
  Pagination,
} from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";

const PartsTable = ({ parts, loading, handleOpen }) => {
  const [page, setPage] = useState(1); // Start page at 1 (Pagination component is 1-based)
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle change in rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1); // Reset to the first page when changing rows per page
  };

  // Calculate the total number of pages
  const totalPages = Math.ceil(parts.length / rowsPerPage);

  // Paginate the data
  const paginatedParts = parts.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow style={{ background: "black", color: "white" }}>
              <TableCell style={{ color: "white" }}>Year Part Model</TableCell>
              <TableCell style={{ color: "white" }}>Image</TableCell>
              <TableCell style={{ color: "white" }}>Description</TableCell>
              <TableCell style={{ color: "white" }}>Miles</TableCell>
              <TableCell style={{ color: "white" }}>Part Grade</TableCell>
              <TableCell style={{ color: "white" }}>US Price</TableCell>
              <TableCell style={{ color: "white" }}>Quotation</TableCell>
              <TableCell style={{ color: "white" }}>Live Chat</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <Typography variant="body1">Loading...</Typography>
                </TableCell>
              </TableRow>
            ) : (
              paginatedParts.map((row, index) => (
                <TableRow key={index}>
                  <TableCell className="tableCell">
                    {row.year} <br /> {row.part} <br /> {row.model_make}
                  </TableCell>
                  <TableCell>
                    <img
                      src={row.image}
                      alt={row.model}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "8px",
                      }}
                    />
                  </TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>{row.miles}</TableCell>
                  <TableCell>{row.part_grade}</TableCell>
                  <TableCell>{`$${row.price}`}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={()=>handleOpen(row)}
                      color="secondary"
                      sx={{ borderRadius: "60px" }}
                    >
                      Send Request
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="success"
                      startIcon={<ChatIcon />}
                      sx={{ borderRadius: "60px" }}
                    >
                      Live Chat
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Custom Pagination */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
      <Typography>{`${page * rowsPerPage + 1} - ${Math.min((page + 1) * rowsPerPage, parts.length)} of ${parts.length} Items`}</Typography>

      <Pagination
          count={totalPages} // Total number of pages
          page={page} // Current page
          onChange={handleChangePage} // Handle page change
          siblingCount={1} // Number of sibling pages to show
          boundaryCount={1} // Number of boundary pages to show
          color="primary" // Custom color (optional)
        />
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
        
        <select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
          {[5, 10, 20, 50].map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <Typography>items per page:</Typography>
 </Box>
        
      </Box>

      
    </>
  );
};

export default PartsTable;
