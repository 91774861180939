import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardMedia, Grid, Typography, Box } from '@mui/material';

const BrandsList = () => {

  const[brandsList,setBrandsList]=useState([])


  async function getBrandsList(){
    const response = await fetch(`${process.env.REACT_APP_API_URL}/oldparts/brands`)
    if(response.ok){
      const data = await response.json()
      setBrandsList(data)
    }else{

    }
    
  }
  useEffect(()=>{
   getBrandsList()
  },[])

    
  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: 4 }}>
        Explore Our Premium Brands
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        {brandsList.map((brand, index) => (
          <Grid item xs={6} sm={4} md={3} lg={2} key={index}>
            <Card sx={{ borderRadius: 4, boxShadow: 3, padding: 2,cursor:"pointer" }} onClick={()=> window.open(`/used/${brand.brand_name}`,"_self")}>
              <CardMedia
                component="img"
                image={brand.brand_image_url}
                alt={brand.brand_name}
                sx={{ objectFit: 'contain', marginBottom: 2 }}
              />
              <CardContent sx={{ textAlign: 'center', padding: '0' }}>
                <Typography variant="subtitle1">{brand.brand_name}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BrandsList;
