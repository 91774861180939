import { Autocomplete, Box, Button, TextField, Typography } from "@mui/material";

function HomeForm(){
    return(<Box sx={{
        backgroundColor: "white", padding: "50px",
        borderRadius: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        width: "500px",
        height: "460px"
      }}>
        <Typography
          variant="h4"
          sx={{ color: "#050b20", fontWeight: "bold" }}
        >
          Find the part
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: "100%",
          }}
        >
          <Autocomplete
            options={["2021", "2020", "2019"]}
            renderInput={(params) => (
              <TextField size="s"
                {...params}
                label="Select Year"
                variant="outlined"
                InputProps={{
                  style: {
                    height: 50, // Adjust the height as needed
                  },
                }}
              />
            )}
          />
          <Autocomplete
            options={["Model A", "Model B", "Model C"]}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Model/ Make"
                variant="outlined" InputProps={{
                  style: {
                    height: 50, // Adjust the height as needed
                  },
                }}
              />
            )}
          />
          <Autocomplete
            options={["Part A", "Part B", "Part C"]}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Part"
                variant="outlined" InputProps={{
                  style: {
                    height: 50, // Adjust the height as needed
                  },
                }}
              />
            )}
          />
          <Autocomplete
            options={["12345", "67890", "11223"]}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Zipcode"
                variant="outlined" InputProps={{
                  style: {
                    height: 50, // Adjust the height as needed
                  },
                }}
              />
            )}
          />
        </Box>
        <Button
          variant="contained"
          sx={{
            bgcolor: "#ffd401",
            borderRadius: "100px",
            width: "100%",
          }}
        >
          <Typography
            variant="body1"
            sx={{ color: "#050b20", fontWeight: "bold" }}
          >
            Next
          </Typography>
        </Button>
      </Box>)
}

export default HomeForm